import axios from "axios";
import querystring from "querystring";

import { checkoutServiceBaseUrl } from "../../core/config";
import { ENDPOINTS } from "./constants";

const validateRequest = (endpoint) => {
  if (!Object.values(ENDPOINTS).includes(endpoint)) {
    return {
      isValid: false,
      errorMessage: `unsupported endpoint ${endpoint}`,
    };
  }

  return {
    isValid: true,
  };
};

export const get = async ({ endpoint, token, params = {} }) => {
  const { isValid, errorMessage } = validateRequest(endpoint);

  if (!isValid) {
    throw new Error(errorMessage);
  }

  const response = await axios.get(endpoint, {
    baseURL: `${checkoutServiceBaseUrl}`,
    params,
    ...(token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}),
  });

  if (response.status !== 200) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response?.data;
};

export const postForm = async ({
  endpoint,
  token,
  query = {},
  body = {},
  withRedirect = false,
}) => {
  const { isValid, errorMessage } = validateRequest(endpoint);

  if (!isValid) {
    throw new Error(errorMessage);
  }

  if (withRedirect) {
    postFormWithRedirect({ endpoint, body });
    return;
  }

  const data = new URLSearchParams();

  Object.keys(body).forEach((key) => data.append(key, body[key]));

  const endpointWithQuery = query
    ? `${endpoint}?${querystring.stringify(query)}`
    : endpoint;

  const response = await axios.post(endpointWithQuery, data, {
    baseURL: `${checkoutServiceBaseUrl}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  if (response.status !== 200) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response?.data;
};

export const post = async ({ endpoint, token, query = {}, body = {} }) => {
  const { isValid, errorMessage } = validateRequest(endpoint);

  if (!isValid) {
    throw new Error(errorMessage);
  }

  const endpointWithQuery =
    Object.keys(query).length > 0
      ? `${endpoint}?${querystring.stringify(query)}`
      : endpoint;

  const response = await axios.post(endpointWithQuery, body, {
    baseURL: `${checkoutServiceBaseUrl}`,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  if (response.status !== 200) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response?.data;
};

export const put = async ({ endpoint, token, query = {}, body = {} }) => {
  const { isValid, errorMessage } = validateRequest(endpoint);

  if (!isValid) {
    throw new Error(errorMessage);
  }

  const endpointWithQuery = query
    ? `${endpoint}?${querystring.stringify(query)}`
    : endpoint;

  const response = await axios.put(endpointWithQuery, body, {
    baseURL: `${checkoutServiceBaseUrl}`,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  });

  if (response.status !== 200) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return response?.data;
};

const postFormWithRedirect = ({ endpoint, body = {} }) => {
  const form = document.createElement("form");
  form.action = `${checkoutServiceBaseUrl}${endpoint}`;
  form.method = "POST";
  form.style.display = "none";

  Object.keys(body).forEach((key) => {
    const elem = document.createElement("input");
    elem.name = key;
    elem.value = body[key];
    form.appendChild(elem);
  });

  document.body.appendChild(form);
  form.submit();
};
