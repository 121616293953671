import { logger } from "../../core";
import { PAYMENT_METHOD } from "../../core/constants";
import { getPromotionMonths, getPromotionType } from "../../utils/promotions";

export const hydratePromotionInfo = (response) => {
  if (
    response?.paymentMethod !== PAYMENT_METHOD.SALES_FINANCE_POINTS &&
    response?.promotions?.length < 1
  ) {
    logger.error(`promotions are mandatory`);
    return null;
  }

  let { promotions = [], promotionReference } = response;

  // TODO: remove when checkout service API returns this info
  // add promotion type and months.
  promotions = promotions.map((curr) => ({
    ...curr,
    type: getPromotionType(curr),
    promotionMonths: getPromotionMonths(curr),
  }));

  const [firstPromotion] = promotions;

  const selectedPromotion =
    promotions?.find(({ value }) => value === promotionReference) ??
    firstPromotion;

  // add selected promotion
  return {
    ...response,
    promotions,
    selectedPromotion,
  };
};
