import { PAYMENT_OPTIONS } from "../../core/constants";

export const getPromotionMonths = ({ label = "", description = "" }) => {
  const [first] = label.match(/\d+/g) || description.match(/\d+/g) || [];
  return Number(first);
};

export const getPromotionType = ({ label = "", description = "" }) => {
  if (label.includes("Minimum") || description.includes("Minimum")) {
    return PAYMENT_OPTIONS.MINIMUM;
  }

  if (label.includes("Flexible") || description.includes("Flexible")) {
    return PAYMENT_OPTIONS.FLEXIBLE;
  }

  if (label.includes("Deferred") || description.includes("Deferred")) {
    return PAYMENT_OPTIONS.DEFERRED;
  }

  if (label.includes("Equal") || description.includes("Equal")) {
    return PAYMENT_OPTIONS.EQUAL;
  }

  return PAYMENT_OPTIONS.DEFAULT;
};
