export const ENDPOINTS = {
  ORDER: "/order",
  PURCHASE: "/purchase",
  MERCHANT: "/merchant",
  PAYMENT_EMBED: "/payment/embed",
  PAYMENT_UPDATE: "/payment/update",
  PAYMENT_WALLET: "/payment/wallet",
  PAYMENT_OUTCOME: "/payment/outcome",
  PAYMENT_ERROR: "/payment/error",
  SHOPIFY_TOKEN: "/shopify/token",
  SHOPIFY_AUTHORIZE: "/shopify/authorize",
  PURCHASE_INITIATE: "/purchase/initiate",
  COMPLETE_CONSENT: "/payment/consent",
  MERCHANT_AUTH: "/authorize",
  TRANSACTION_SEARCH: "/transaction/search",
  DAILY_STATEMENTS: "/statement/overview",
  MERCHANT_GET_ORDERS: "/merchant/orders",
  MERCHANT_TRANSACTION: "/merchant/order/{transactionId}",
  MERCHANT_SEND_LINK: "/purchase/link",
  ACCOUNT_PROFILES: "/account/profiles",
  MERCHANT_GET_PROFILE: "/merchant/profile",
  MERCHANT_MANUAL_PAYMENT: "/merchant/manualpayment/{transactionId}",
  CUSTOMER_TRANSACTION: "/customer/transaction",
  VIEW_REPORTS:"/merchant/reports/settlement", // TODO: move to ab-report-service
  DOWNLOAD_FILE: "/merchant/reports/settlement/download", // TODO: move to ab-report-service
  REFUND: "/refund",
  VOID: "/void",
  CAPTURE: "/capture",
};
